<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">{{ pop.state_txt }} 처리</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{{ pop.state_txt }}일자</th>
                            <td>
                                <CarrotDatePicker v-model="pop.executed_date" class="w-160px"></CarrotDatePicker>
                            </td>
                        </tr>
                        <tr>
                            <th>사유</th>
                            <td>
                                <textarea class="w-100per h-100px" v-model="pop.contents"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left" @click="pop.onClose">취소</button>
                <button class="btn-default float-right" @click="pop.doSubmit">저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    name: 'HQStateEditPopup',
    components: {
        CarrotDatePicker,
    },
    props: {
        idx: {
            type: Number,
            default: 0
        },
        state : {
            type: String,
            default: ""
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx : 0,
            state : "",
            state_txt : "",
            executed_date : "",
            contents : "",

            doSubmit: () => {
                let params = {
                    idx : pop.idx,
                    state : pop.state,
                    executed_date : pop.executed_date,
                    contents : pop.contents
                };

                if( !params.executed_date ){
                    alert("항목을 모두 입력해주세요.\n:: " + pop.state_txt + "날짜");
                    return;
                }

                if( !params.contents ){
                    alert("항목을 모두 입력해주세요.\n:: 사유");
                    return;
                }

                axios.post("/rest/mypims/setHQState", params).then((res) => {
                    if( res.data.err == 0 ){
                        pop.onClose();
                    } else {
                        console.info(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx?props.idx:0;
            pop.state = props.state;

            if( pop.state == "QUIT" ){
                pop.state_txt = "퇴사";
            } else if(pop.state == "BABYLEAVE") {
                pop.state_txt = "육아 휴직";
            } else {
                pop.state_txt = "휴직";
            }
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>