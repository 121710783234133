<template>
    <div v-if="view.isLoaded==true" class="con-wrap">
        <!-- <ResignationTreatmentAddPopup v-if="pop.isShow" @close="pop.isShow=false"></ResignationTreatmentAddPopup> -->
        <!-- <ResignationTreatmentViewPopup v-if="pop.isShow" @close="pop.isShow=false"></ResignationTreatmentViewPopup> -->
        <!-- <LeaveTreatmentAddPopup v-if="pop.isShow" @close="pop.isShow=false"></LeaveTreatmentAddPopup> -->
        <!-- <LeaveTreatmentViewPopup v-if="pop.isShow" @close="pop.isShow=false"></LeaveTreatmentViewPopup> -->

        <HQStateViewPopup v-if="pop.show_view_state" :idx="pop.idx" @close="pop.show_view_state=false"></HQStateViewPopup>
        <HQStateEditPopup v-if="pop.show_edit_state" :idx="pop.idx" :state="pop.state" @close="pop.hideStateEdit"></HQStateEditPopup>

        <HeadTeamSelectPopup v-if="pop.show_team_select" :idx="pop.idx" @close="pop.hideTeamSelect"></HeadTeamSelectPopup>

        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') 인사카드'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div class="tabDefult-col">
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab1-idx', params : { idx:view.idx }}"><button class="tab-default">인적사항</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab2-idx', params : { idx:view.idx }}"><button class="tab-default on">인사History</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab3-idx', params : { idx:view.idx }}"><button class="tab-default">평가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab4-idx', params : { idx:view.idx }}"><button class="tab-default">휴가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab5-idx', params : { idx:view.idx }}"><button class="tab-default">교육</button></router-link>
                    <!-- <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab6-idx', params : { idx:view.idx }}"><button class="tab-default">권한</button></router-link> -->
                </div>

                <div class="po-relative">
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>입사일자</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <CarrotDatePicker v-model="view.info.joindate" class="w-160px"></CarrotDatePicker>
                                </td>
                                <th>퇴직/휴직 처리</th>
                                <td>
                                    <span v-if="view.info.state=='DUTY'" class="mr-20">재직 중</span>
                                    <div v-else>
                                        <span class="btn-view mr-20" @click="pop.showStateView">{{ view.info.state_txt }} {{ view.info.resignationday }}</span>
                                        <button class="btn-default dp-inblock h-30px" @click="mod.doRehire">재직 처리</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>ID(Email)</th>
                                <td>
                                    {{ view.info.email }}
                                </td>
                                <th>비밀번호</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <span class="btn-view mr-20" @click="mod.initializePWD">비밀번호 초기화</span>
                                </td>
                            </tr>
                            <tr>
                                <th class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    부문/본부/팀
                                    <div class="mt-10">
                                        <button class="btn-default dp-inblock h-30px" @click="pop.showTeamSelect">선택</button>
                                    </div>
                                </th>
                                <td>
                                    <div>
                                        <span class="dp-inblock bold w-40px">부문</span>
                                        <span class="dp-inblock">{{ view.info.sector_name }}
                                          <span v-if="view.info.sector_name!=null">(main)</span>
                                        </span>
                                        <template v-for="(irow, i) in view.teams_history.sectors" :key="i">
                                          <span class="dp-inblock" v-if="irow.is_main=='N'">, {{ irow.kname }}</span>
                                        </template>
                                      <br>
                                        <span class="dp-inblock bold w-40px">본부</span>
                                        <span class="dp-inblock">{{ view.info.office_name }}
                                          <span v-if="view.info.office_name!=null">(main)</span>
                                        </span>
                                        <template v-for="(irow, i) in view.teams_history.offices" :key="i">
                                        <span class="dp-inblock" v-if="irow.is_main=='N'">, {{ irow.kname }}</span>
                                        </template>
                                    </div>
                                    <div class="mt-5">
                                        <span class="dp-inblock bold w-40px">팀</span>
                                        <span class="dp-inblock">{{ view.info.team_name }}
                                          <span v-if="view.info.team_name!=null">(main)</span>
                                        </span>
                                        <template v-for="(irow, i) in view.teams_history.teams" :key="i">
                                        <span class="dp-inblock" v-if="irow.is_main=='N'">, {{ irow.kname }}</span>
                                        </template>
                                    </div>
                                </td>
                                <th>담당업무</th>
                                <td >
                                    <input type="text" v-model="view.info.assigned_task" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th>직급</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <CarrotPosition :type="'A'" v-model="view.info.idx_position"></CarrotPosition>
                                  <br>
                                  <label v-if="view.info.idx_position==6150" key="CTM"><input type="checkbox" v-model="view.checkedAuth" value="CTM"><span class="ml-5 mr-20"> CTM</span></label>
                                  <label v-if="view.info.idx_position==6150" key="ITM"><input type="checkbox" v-model="view.checkedAuth" value="ITM"><span class="ml-5 mr-20"> ITM</span></label>
                                  <label v-if="view.info.idx_position==6150" key="CTM"><input type="checkbox" v-model="view.checkedAuth" value="MANGO"><span class="ml-5 mr-20"> MangoTalk</span></label>
                                  <label v-if="view.info.idx_position==6150" key="CTM"><input type="checkbox" v-model="view.checkedAuth" value="삼성BPO"><span class="ml-5 mr-20"> 삼성BPO</span></label>
                                  <span v-if="view.info.idx_position==6150"><br>※ ITM/CTM 계정 동기화 주기는 매 시 정각 입니다.</span>
                                </td>
                                <th>법인</th>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <carrot-corporation v-model="view.info.corporation"></carrot-corporation>
                                </td>
                            </tr>
                            <tr>
                                <th>Probation</th>
                                <td colspan="3" class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <label><input type="radio" value="Y" v-model="view.info.is_probation"><span class="ml-5 mr-20"> Y</span></label>
                                    <label><input type="radio" value="N" v-model="view.info.is_probation"><span class="ml-5 mr-20"> N</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>접근권한</th>
                                <td colspan="3" class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <label v-for="(item, i) in view.authlist" :key="i">
                                        <input type="checkbox" v-model="view.checkedAuth" :value="item"><span class="ml-5 mr-20"> {{ item }}</span>
                                    </label>
                                    <div v-if="view.isShowAuth" class="mt-5">
                                        <p><span class="color-1">*</span> 수정 시 사유를 입력해주세요.</p>
                                        <p>
                                            <span class="color-1">*</span> 사유 : <input type="text" v-model="view.auth.reason" class="w-80per ml-5">
                                            <!-- <button class="btn-default float-right" v-if="view.info.state!='QUIT'">권한부여</button> -->
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">인사발령사항</div>
                        <button class="btn-default float-right" v-if="view.info.state!='QUIT'" @click="mod.addHistory">+ 항목 추가</button>
                        <div class="clear"></div>
                    </div>
                    <div class="po-relative">
                        <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                        <table class="table-col">
                            <colgroup>
                                <col width="120">
                                <col width="150">
                                <col width="150">
                                <col width="470">
                                <col width="310">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>발령일</th>
                                    <th>승진예정일</th>
                                    <th>발령사항</th>
                                    <th>기타사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in view.history.list" :key="i">
                                    <td>
                                        <select class="w-100per" v-model="irow.htype">
                                            <option value="">선택</option>
                                            <option value="JOIN">입사</option>
                                            <option value="PROMO">승진</option>
                                            <option value="MOVE">이동</option>
                                            <option value="LEAVE">휴직</option>
                                            <option value="BABYLEAVE">육아휴직</option>
                                            <option value="QUIT">퇴직</option>
                                            <!-- <option value="DUTY">재직</option> -->
                                            <option value="REHIRE">복직</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" v-model="irow.executed_date" class="w-130px">
                                    </td>
                                    <td>
                                        <input type="text" v-model="irow.expected_date" class="w-130px">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.contents">
                                    </td>
                                    <td>
                                        <input type="text" class="w-100per" v-model.trim="irow.etc">
                                    </td>
                                </tr>
                                <tr v-if="view.history.list.length==0">
                                    <td colspan="5">인사이력 변경사항이 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">접근권한 변경 이력</div>
                        <div class="clear"></div>
                    </div>
                    <div class="po-relative">
                        <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                        <table class="table-col">
                            <colgroup>
                                <col width="150">
                                <col width="150">
                                <col width="450">
                                <col width="450">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>수정일</th>
                                    <th>수정자</th>
                                    <th>부여권한</th>
                                    <th>사유</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in view.authlog.list" :key="i">
                                    <td>
                                        {{ irow.createdate }}
                                    </td>
                                    <td>
                                        {{ irow.ename }} ( {{ irow.kname }} )
                                    </td>
                                    <td>
                                        {{ irow.auth }}
                                    </td>
                                    <td>
                                        {{ irow.reason }}
                                    </td>
                                </tr>
                                <tr v-if="view.authlog.list.length==0">
                                    <td colspan="4">접근권한 변경이력이 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        <CarrotPaging :total="view.authlog.total" :list_per_page="view.rows" v-model="view.page" @change="view.doSearchAuth"></CarrotPaging>
                    </div>

                    <div class="block-title mt-50 mb-20">특이사항</div>
                    <table class="table-col">
                        <colgroup>
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>특이사항</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="po-relative">
                                    <div class="lock-contents" v-if="view.info.state=='QUIT'"></div>
                                    <textarea class="w-100per h-100px" v-model.trim="view.info.specialtext"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <button class="btn-default float-left mt-30 ml-20" v-if="view.info.state!='QUIT'" @click="pop.showStateEdit('QUIT')">퇴사 처리</button>
                    <button class="btn-default float-left mt-30 ml-20" v-if="view.info.state=='DUTY'" @click="pop.showStateEdit('LEAVE')">휴직 처리</button>
                    <button class="btn-default float-left mt-30 ml-20" v-if="view.info.state=='DUTY'" @click="pop.showStateEdit('BABYLEAVE')">육아휴직 처리</button>
                    <button class="btn-default float-right mt-30" v-if="view.info.state!='QUIT'" @click="mod.doSubmit">저장</button>
                    <div class="clear"></div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotPosition from '@/components/common/CarrotPosition.vue'
import CarrotCorporation from '@/components/common/CarrotCorporation.vue'
import HeadTeamSelectPopup from '@/components/popup/myPIMS/PersonalInfoList/HeadTeamSelectPopup.vue'
import HQStateViewPopup from '@/components/popup/myPIMS/PersonalInfoList/HQStateViewPopup.vue'
import HQStateEditPopup from '@/components/popup/myPIMS/PersonalInfoList/HQStateEditPopup.vue'
import Swal from "sweetalert2";
import {useStore} from "vuex";

// import ResignationTreatmentAddPopup from '@/components/popup/myPIMS/ResignationTreatmentAddPopup.vue'
// import ResignationTreatmentViewPopup from '@/components/popup/myPIMS/ResignationTreatmentViewPopup.vue'
// import LeaveTreatmentAddPopup from '@/components/popup/myPIMS/LeaveTreatmentAddPopup.vue'
// import LeaveTreatmentViewPopup from '@/components/popup/myPIMS/LeaveTreatmentViewPopup.vue'


export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        CarrotPosition,
        CarrotCorporation,
        HeadTeamSelectPopup, 
        HQStateViewPopup, 
        HQStateEditPopup,
        // ResignationTreatmentAddPopup, 
        // ResignationTreatmentViewPopup, 
        // LeaveTreatmentAddPopup, 
        // LeaveTreatmentViewPopup
    },
    setup(changeData) {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast()
        const store  = useStore();

        const pop = reactive({
            idx : 0,
            // Popup 노출 여부
            show_view_state  : false,
            show_edit_state  : false,
            show_team_select : false,
            showStateView : () => {
                // 재직 상태 상세 팝업
                pop.idx = view.idx;
                pop.show_view_state = true;
            },

            showStateEdit : (state) => {
                // 퇴사/휴직 처리 팝업
                pop.idx = view.idx;
                pop.state = state;
                pop.show_edit_state = true;
            },

            hideStateEdit : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/mypims/getHQHistory", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info.idx_position=res.data.idx_position;
                        view.info.state     = res.data.state;
                        view.info.state_txt = res.data.state_txt;


                      } else {
                          console.log(res.data.err_msg);
                      }
                    pop.show_edit_state = false;
                });
            },

            showTeamSelect : () => {
                // 본부/팀 선택 팝업
                pop.idx = view.idx;
                pop.idx_office = view.info.idx_office;
                pop.idx_team   = view.info.idx_team;
                pop.show_team_select = true;
            },

            hideTeamSelect : () => {
                view.doSearch();
                pop.show_team_select = false;

                // let params = {
                //     idx : view.idx
                // };

                // axios.get("/rest/mypims/getHQHistory", { params : params }).then((res) => {
                //     if( res.data.err == 0 ){
                //         view.info.idx_office  = res.data.idx_office;
                //         view.info.idx_team    = res.data.idx_team;
                //         view.info.idx_office2 = res.data.idx_office2;
                //         view.info.idx_team2   = res.data.idx_team2;
                //     } else {
                //         console.log(res.data.err_msg);
                //     }
                //     pop.show_team_select = false;
                // });
            }
        });

        const mod = reactive({
            idx : 0,

            doRehire : () => {
                // 재고용
                let params = {
                    idx : view.idx
                };

                axios.post("/rest/mypims/setRehire", params).then((res) => {
                    if( res.data.err == 0 ){
                        view.info.state     = res.data.state;
                        view.info.state_txt = res.data.state_txt;
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            initializePWD : () => {
                // 비밀번호 초기화
                let params = {
                    idx : view.idx
                };

                if( confirm("초기화 하시겠습니까?") == true ){
                    axios.post("/rest/mypims/initializePWD", params).then((res) => {
                        if( res.data.err == 0 ){
                            alert("'carrot'으로 비밀번호 초기화");
                        } else {
                            if( res.data.err_msg ) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            addHistory : () => {
                view.history.list.push({
                    idx : 0, htype : "", executed_date : "", expected_date : "", contents : "", etc : ""
                });
            },
            
            doSubmit : () => {
                // 저장
                if(store.state.pd_auth!=true){
                  Swal.fire({
                    title : '인사카드 상세',
                    text  : '수정 권한이 없습니다.'
                  });
                  return;
                }
                if(view.isShowAuth === true && view.auth.reason == ""){
                    alert("접근권한 사유를 입력해주세요.");
                    return;
                }
                let params = {
                    idx : view.idx,
                    joindate     : view.info.joindate,
                    assigned_task : view.info.assigned_task,
                    // idx_office   : view.info.idx_office,
                    // idx_team     : view.info.idx_team,
                    // office_list  : view.office_list,
                    // team_list    : view.team_list,
                    idx_position : view.info.idx_position,
                    corporation  : view.info.corporation,
                    is_probation : view.info.is_probation,
                    histories    : view.history.list,
                    specialtext  : view.info.specialtext,
                    auth_arr    : view.checkedAuth,
                    reason      : view.auth.reason ?? ""
                };

                axios.post("/rest/mypims/modHQHistory", params).then((res) => {
                    if( res.data.err == 0 ){
                        alert("저장되었습니다.");
                        view.doSearch();
                        router.push({
                            name : "myPIMS-IncumbentManagementList"
                        })
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            }
        })
        const view = reactive({
            isLoaded : false,
            isShowAuth: false,
            checkedAuth: [],

            idx  : 0,
            page  : 1,
            rows  : 5,

            info    : {},
            history : {},

            teams_history : {},
            changeData: watch.changeData,
            goList : () => {
                if(changeData === true){
                        if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                        router.push({
                            name : "myPIMS-IncumbentManagementList"
                        });
                    }
                } else{
                    router.push({
                    name : "myPIMS-IncumbentManagementList"
                    });
                }               
            },

            viewTab : (id) => {
                router.push({
                    name   : 'myPIMS-PersonalInfoList_tab' + id + '-idx',
                    params : { idx : view.idx }
                });
            },
            doSearchAuth : () =>{
                let params = {
                    idx : view.idx,
                    page : view.page,
                    rows : view.rows
                }

                axios.get("/rest/mypims/getHQAuthLog", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.authlog = res.data.authlog;
                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : view.idx,
                    page : view.page,
                    rows : view.rows,
                  user_position: store.state.idx_position,
                  user_sector: store.state.idx_sector,
                  user_office: store.state.idx_office,
                  user_team: store.state.idx_team
                };

                axios.get("/rest/mypims/getHQHistory", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info    = res.data;
                        view.history = res.data.history;
                        view.auth = res.data.auth;

                        view.authlog = res.data.authlog;
                        view.teams_history = res.data.teams;

                        view.isLoaded = true;

                        //인사카드-tab2 열람권한 체크 추가
                        view.viewAuth=false;
                        if(store.state.isMaster || (view.info.auth.dept && view.info.auth.position)) //마스터권한 또는 (부문/본부/팀+직급 체크)
                          view.viewAuth=true;

                        if(store.state.pd_auth)
                          view.viewAuth=true;

                        if(view.viewAuth==false){
                          router.push({ name : 'main' });
                          Swal.fire({
                            title : '인사카드 상세',
                            text  : '접근 권한이 없습니다.'
                          });
                          return;
                        }

                        view.authlist = view.info.arr_auth;
                        view.checkedAuth = view.info.arr_authinfo;
                        
                        view.checkAuth();

                    } else {
                        console.log(res.data.err_msg);
                    }
                });
            },
            checkAuth: () => {
                const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);
                const authchk = [...view.checkedAuth].sort();
                const authinfo = [...view.info.arr_authinfo].sort();
                view.isShowAuth = (equals(authchk, authinfo) === false) ?  true : false;
                if(view.isShowAuth === false) view.auth.reason = "";
            }
        });

        watch(() => view.checkedAuth,  () => {
            view.checkAuth();
        });

        watch(() => [view.info.assigned_task,view.info.joindate,view.info.idx_position,view.info.corporation,view.info.is_probation],  (nVal, oval) => {
            changeData = false;
            if (oval[0] !== undefined && oval[1] !== undefined && oval[2] !== undefined && oval[3] !== undefined && oval[4] !== undefined) {
                if(nVal != oval) {
                    changeData = true;
                }
            }
            console.log('수정된 항목 수',changeData,nVal,oval);
            /* console.log('ㅎㅇ',nVal);
            console.log('ㅂㅇ',oval); */
            return{
                changeData,
            }
            
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
        });

        return {pop, mod, view};
    }
}
</script>

<style lang="scss" scoped>
</style>