<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">{{ pop.state_txt }} 처리</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{{ pop.state_txt }}일자</th>
                            <td>{{ pop.executed_date }}</td>
                        </tr>
                        <tr>
                            <th>사유</th>
                            <td>
                                <div class="preline">{{ pop.contents }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'HQStateViewPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx : 0,
            state         : "",
            state_txt     : "",
            executed_date : "",
            contents      : "",

            doSearch: () => {
                let params = {
                    idx : pop.idx
                };

                axios.get("/rest/mypims/getHQStateInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.state         = res.data.state;
                        pop.state_txt     = res.data.state_txt;
                        pop.executed_date = res.data.executed_date;
                        pop.contents      = res.data.contents;
                    } else {
                        console.info(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx?props.idx:0;
            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>