<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-700px">
            <div>
                <p class="title">부문/본부/팀 선택</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="con-top mb-20">
                <div class="con-top-txt">
                    - 부문/본부/팀 중복 선택 가능합니다.<br>
                    - 선택한 부문/본부에 해당하는 팀이 노출됩니다.<br>
                    - Main으로 설정된 부문/본부/팀 으로 통계가 적용됩니다.
                </div>
            </div>
          <div class="mt-10 tbl-light dp-table w-100per">
            <div class="float-left w-100per pr-10">
              <div class="border-box">
                <table class="table-row">
                  <colgroup>
                    <col width="100">
                    <col width="*">
                  </colgroup>
                  <thead style="border-top: 0px; ">
                  <tr>
                    <th>MAIN</th>
                    <td>
                      <CarrotSector v-model="pop.idx_sector"></CarrotSector>
                      <CarrotDept v-model:idx_sector.sync="pop.idx_sector"  v-model="pop.idx_office"  ></CarrotDept>
                      <CarrotTeam :idx_sector="pop.idx_sector" :idx_office="pop.idx_office" v-model="pop.idx_team"></CarrotTeam>
                    </td>
                  </tr>
                  <tr class="h-150px overscroll">
                    <th>SUB</th>
                    <td style="vertical-align: top" >
                      <span v-for="i in pop.num+1" :key="i">
                        <CarrotSector v-model="pop.sub_sector[i-1]"></CarrotSector>
                        <CarrotDept v-model:idx_sector.sync="pop.sub_sector[i-1]"  v-model="pop.sub_office[i-1]" ></CarrotDept>
                        <CarrotTeam :idx_sector="pop.sub_sector[i-1]" :idx_office="pop.sub_office[i-1]" v-model="pop.sub_team[i-1]"></CarrotTeam>
                        <a v-if="pop.num>=1 && pop.num==i-1" style="cursor:pointer" @click="pop.minusNum">x</a>
                        <br>
                      </span>
                    </td>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="layer-btn txt-right mt-10">
                <button class="btn-default dp-inblock" @click="pop.addNum">SUB팀추가</button>
              </div>
            </div>
            </div>
            <div class="layer-btn txt-center mt-10">
                <button class="btn-default dp-inblock" @click="pop.doSubmit">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'
import CarrotDept from "@/components/common/CarrotDept";
import CarrotTeam from "@/components/common/CarrotTeam";
import CarrotSector from "@/components/common/CarrotSector";

export default {
    name: 'HeadTeamSelectPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
  components: {
    CarrotDept,
    CarrotTeam,
    CarrotSector
  },
    setup(props, {emit}) {
        const pop = reactive({
            idx  : 0,

            selectable : true,
            offices : [],
            teams   : [],
            selected_offices : [],
            selected_teams   : [],

            main_office : 0,
            main_team   : 0,
            selected : 0,
            idx_sector:0,
            idx_office:0,
            idx_team:0,
            num:0,
            sub_sector: {},
            sub_office:{},
            sub_team:{},

            addNum:() => {
              pop.num=pop.num+1;
            },
            minusNum:() => {

              let temp_sector =pop.sub_sector;
              let temp_office =pop.sub_office;
              let temp_team =pop.sub_team;


              pop.sub_sector={};
              pop.sub_office={};
              pop.sub_team={};
              for(let i=0; i<pop.num; i++){
                pop.sub_sector[i]=temp_sector[i];
                pop.sub_office[i]=temp_office[i];
                pop.sub_team[i]=temp_team[i];
              }
              pop.num=pop.num-1;
            },
            doSubmit : () => {

              let params = {
                idx : pop.idx,
                idx_sector: pop.idx_sector,
                idx_office: pop.idx_office,
                idx_team: pop.idx_team,
                sub_sector: pop.sub_sector,
                sub_office: pop.sub_office,
                sub_team: pop.sub_team
              };
              console.log(params);

              axios.post("/rest/mypims/setHQSectorInfo", params).then((res) => {
                if( res.data.err == 0 ){
                  pop.onClose();
                } else {
                  console.info(res.data.err_msg);
                }
              });


            },


            doSearch : () => {
                let params = {
                    idx : pop.idx
                };

                /*
                axios.get("/rest/mypims/getHQTeamInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.selected_offices = res.data.selected_offices;
                        pop.selected_teams   = res.data.selected_teams;
                        pop.main_office      = Number(res.data.main_office);
                        pop.main_team        = Number(res.data.main_team);

                        if( res.data.idx_position == "6211" ){
                            pop.selectable = false;
                        }
                        console.log(pop.main_team);
                    } else {
                        console.info(res.data.err_msg);
                    }

                    console.log(pop.selectable);
                });
                */
              axios.get("/rest/mypims/getHQSectorInfo", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  pop.idx_sector = res.data.idx_sector;

                  pop.idx_office   = res.data.idx_office;
                  pop.idx_team   = res.data.idx_team;
                  pop.sub_sector = res.data.sub_sector;
                  pop.sub_office  = res.data.sub_office;
                  pop.sub_team   = res.data.sub_team;
                  pop.num= res.data.num;

                  if( res.data.idx_position == "6211" ){
                    pop.selectable = false;
                  }
                } else {
                  console.info(res.data.err_msg);
                }

                console.log(pop.selectable);
              });
            },

            onClose: () => {
                emit('close');
            }
        });

        watch(() => pop.main_office, (nVal, oVal) => {
            if(nVal != oVal && nVal) {
                nVal = Number(nVal);
                if( pop.selected_offices.indexOf(nVal) < 0 ){
                    pop.selected_offices.push(nVal);
                }
            }
        });

        watch(() => pop.main_team, (nVal, oVal) => {
            if(nVal != oVal && nVal) {
                nVal = Number(nVal);
                if( pop.selected_teams.indexOf(nVal) < 0 ){
                    pop.selected_teams.push(nVal);
                }
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx = props.idx?Number(props.idx):0;
            pop.doSearch();
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>